import React, { FC, useContext, useRef, useState, memo, useMemo } from "react";
import { graphql, Link } from "gatsby";
import { useMatch } from "@reach/router";
import { ClimateThemePageQuery, BlockFragment } from "../../graphql-types";
import {
  ySpaceRem,
  colors,
  xSpaceRem,
  fontStacks,
  targetBaseFontSizePx,
} from "../global-style";
import { Center, centerGutterRem } from "../components/center";
import ReactMapboxGl, { ZoomControl, Source, Layer } from "react-mapbox-gl";
import { WavySection } from "../components/wavy-section";
import { Blocks } from "../components/blocks";
import { Footer } from "../components/footer";
import { LayoutContext } from "../components/layout";
import { css } from "styled-components";
import { PersistedData } from "../components/persisted-data";
import { sortBy, mapValues, mapKeys } from "lodash";
import { Grid } from "../components/grid";
import { StoryPreview } from "../components/story-preview";
import { flatMap } from "lodash";
import { SEO } from "../components/seo";

const Map =
  typeof window !== "undefined"
    ? ReactMapboxGl({
        accessToken:
          "pk.eyJ1Ijoid2ViYmEtYnYiLCJhIjoiY2s3MHVodHJmMDBiMzNmcG93MjRrMHdvaSJ9.zy7otgBP3DsU6ilzCe_9Yw",
      })
    : () => <div />;

type Props = {
  data: ClimateThemePageQuery;
};

const ClimateTheme: FC<Props> = memo(props => {
  const { data } = props;

  const { remInPx, waveClearance } = useContext(LayoutContext);

  const [postalCodeInput, setPostalCodeInput] = useState({
    value: "",
    touched: false,
  });

  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  const routeMatch = useMatch("/klimaatthemas/:climateTheme") as
    | (ReturnType<typeof useMatch> & { climateTheme: string })
    | null;

  const sortedClimateThemes = sortBy(
    data.wp.climateThemes?.edges,
    edge => edge?.node?.menuOrder
  );

  const climateTheme = sortedClimateThemes.find(
    edge => edge?.node?.slug === routeMatch?.climateTheme
  );

  const nthClimateThemeIsSelected = (i: number) =>
    sortedClimateThemes[i]?.node?.slug === climateTheme?.node?.slug;

  const tabRadius = `${0.25 * xSpaceRem}rem`;

  const tileJsonSources = useMemo(
    () =>
      mapValues(
        mapKeys(
          {
            ...flatMap(
              props.data.wp.climateThemes?.edges,
              edge => edge?.node?.climateThemeCustomFields?.map?.mapboxTilesets
            ),
          },
          tileset => tileset?.id
        ),
        tileset => ({
          type: "raster",
          tileSize: 256,
          tiles: [
            `https://api.mapbox.com/v4/${tileset?.id}/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoid2ViYmEtYnYiLCJhIjoiY2s3MHVodHJmMDBiMzNmcG93MjRrMHdvaSJ9.zy7otgBP3DsU6ilzCe_9Yw`,
          ],
        })
      ),
    [climateTheme]
  );

  return (
    <>
      <SEO {...climateTheme?.node?.seo} />
      <PersistedData>
        {({
          postalCode,
          setPostalCode,
          mapCenter,
          setMapCenter,
          mapZoom,
          setMapZoom,
        }) => {
          if (!postalCodeInput.touched && postalCode) {
            setPostalCodeInput({
              value: postalCode,
              touched: true,
            });
          }

          const updateMap = (map: any) => {
            setMapZoom(map.transform._zoom);
            setMapCenter([
              map.transform._center.lng,
              map.transform._center.lat,
            ]);
          };

          return (
            <>
              <WavySection
                backgroundColor={colors.mediumBlue}
                style={{ color: colors.white, paddingTop: waveClearance }}
              >
                <Center childrenAreCentered>
                  <div
                    style={{
                      ...(pageTitleRef.current &&
                        (() => {
                          const paddingYRem =
                            ySpaceRem / 2 -
                            ((pageTitleRef.current.getBoundingClientRect()
                              .height /
                              remInPx) %
                              (ySpaceRem / 2));

                          return {
                            paddingTop: `${0.5 * paddingYRem}rem`,
                            paddingBottom: `${0.5 * paddingYRem}rem`,
                          };
                        })()),
                      transition: "padding 0.25s ease",
                    }}
                  >
                    <h2
                      ref={pageTitleRef}
                      style={{
                        textAlign: "center",
                        paddingTop: `${ySpaceRem}rem`,
                        paddingBottom: `${ySpaceRem}rem`,
                        fontFamily: fontStacks.cubano,
                      }}
                      css={(() => {
                        const minFontSizeRem = 1.5555;
                        const maxFontSizeRem = 2.8888;
                        const vwRangeStartPx = 500;

                        return css`
                          font-size: ${(minFontSizeRem *
                            (remInPx || targetBaseFontSizePx)) /
                            (vwRangeStartPx / 100)}vw;
                          line-height: 1;

                          @media (max-width: ${vwRangeStartPx}px) {
                            font-size: ${minFontSizeRem}rem;
                          }

                          @media (min-width: ${vwRangeStartPx *
                              (maxFontSizeRem / minFontSizeRem)}px) {
                            font-size: ${maxFontSizeRem}rem;
                          }
                        `;
                      })()}
                    >
                      Het klimaat verandert.
                      <br /> Dit merkt u in uw buurt door:
                    </h2>
                  </div>
                </Center>

                <Center childrenAreCentered hasGutters={false}>
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: colors.white,
                      width: "100%",
                      maxWidth: `${28 * xSpaceRem}rem`,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: colors.mediumBlue,
                        width: `${centerGutterRem}rem`,
                        ...(nthClimateThemeIsSelected(0) && {
                          borderBottomRightRadius: tabRadius,
                        }),
                        transition: "border-radius 0.25s ease",
                      }}
                    />

                    {sortedClimateThemes.map((edge, i) => (
                      <Link
                        state={{ scrollToTop: false }}
                        key={i}
                        to={`/${edge?.node?.uri}`}
                        style={{
                          textAlign: "center",
                          paddingLeft: `${0.25 * xSpaceRem}rem`,
                          paddingRight: `${0.25 * xSpaceRem}rem`,
                          paddingTop: `${0.5 * ySpaceRem}rem`,
                          paddingBottom: `${0.5 * ySpaceRem}rem`,
                          flexBasis: 0,
                          flexGrow: 1,
                          transition: "transform 0.25s ease",
                          fontWeight: 700,

                          ...(i !== 0 &&
                            !nthClimateThemeIsSelected(i - 1) &&
                            !nthClimateThemeIsSelected(i) && { marginLeft: 1 }),

                          ...(nthClimateThemeIsSelected(i)
                            ? {
                                backgroundColor: colors.white,
                                color: colors.black,
                                transform: `translateY(-${0.25 *
                                  ySpaceRem}rem)`,
                                borderTopLeftRadius: 3,
                                borderTopRightRadius: 3,
                              }
                            : { backgroundColor: colors.orange }),

                          ...(nthClimateThemeIsSelected(i + 1) && {
                            borderBottomRightRadius: tabRadius,
                          }),

                          ...(nthClimateThemeIsSelected(i - 1) && {
                            borderBottomLeftRadius: tabRadius,
                          }),
                        }}
                      >
                        {edge?.node?.title}
                      </Link>
                    ))}

                    <div
                      style={{
                        backgroundColor: colors.mediumBlue,
                        width: `${centerGutterRem}rem`,
                        ...(nthClimateThemeIsSelected(
                          (data.wp.climateThemes?.edges?.length || 0) - 1
                        ) && {
                          borderBottomLeftRadius: tabRadius,
                        }),
                        transition: "border-radius 0.25s ease",
                      }}
                    />
                  </div>
                </Center>
              </WavySection>

              <WavySection
                backgroundColor={colors.white}
                style={{
                  paddingTop: `${2 * ySpaceRem}rem`,
                  paddingBottom: `calc(${waveClearance} + ${1.5 *
                    ySpaceRem}rem)`,
                }}
              >
                <Center>
                  <h2
                    style={{
                      marginBottom: `${ySpaceRem}rem`,
                      fontFamily: fontStacks.cubano,
                    }}
                  >
                    {climateTheme?.node?.climateThemeCustomFields?.map?.title}
                  </h2>

                  {/* 1 */}
                  <div
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                          flexGrow: 999,
                          flexBasis: `${25 * xSpaceRem}rem`,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: colors.lightestGrey,
                            padding: `${0.5 * ySpaceRem}rem ${0.5 *
                              xSpaceRem}rem`,
                          }}
                        >
                          <h3
                            style={{
                              textAlign: "center",
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                            }}
                          >
                            {
                              climateTheme?.node?.climateThemeCustomFields?.map
                                ?.title
                            }{" "}
                            {postalCode}
                          </h3>
                          <div
                            style={{
                              backgroundColor: colors.white,
                              padding: `${0.5 * ySpaceRem}rem ${0.5 *
                                xSpaceRem}rem`,
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                            }}
                          >
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                                setPostalCode(postalCodeInput.value, () => {
                                  // no-op
                                });
                              }}
                            >
                              <label>
                                <p>Uw postcode:</p>

                                <input
                                  value={postalCodeInput.value}
                                  onChange={e => {
                                    setPostalCodeInput({
                                      value: e.target.value,
                                      touched: true,
                                    });
                                  }}
                                  style={{
                                    marginTop: `${0.5 * ySpaceRem}rem`,
                                    height: `${1.5 * ySpaceRem}rem`,
                                    borderRadius: "0.5rem",
                                    border: `2px solid ${colors.lightGrey}`,
                                    paddingLeft: `${0.5 * xSpaceRem}rem`,
                                    paddingRight: `${0.5 * xSpaceRem}rem`,
                                    fontSize: "1rem",
                                    fontFamily: fontStacks.univiaPro,
                                  }}
                                />
                              </label>

                              <button
                                style={{
                                  display: "block",
                                  marginTop: `${0.5 * ySpaceRem}rem`,
                                  borderRadius: "1.5rem",
                                  border: "none",
                                  backgroundColor: colors.orange,
                                  fontSize: "1rem",
                                  fontWeight: 700,
                                  fontFamily: fontStacks.univiaPro,
                                  color: colors.white,
                                  paddingTop: `${0.25 * xSpaceRem}rem`,
                                  paddingBottom: `${0.25 * ySpaceRem}rem`,
                                  paddingLeft: `${0.5 * xSpaceRem}rem`,
                                  paddingRight: `${0.5 * ySpaceRem}rem`,
                                  textAlign: "center",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Toon kaart
                              </button>
                            </form>
                          </div>
                          <div
                            style={{
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                              position: "relative",
                            }}
                            css={`
                              & .mapboxgl-map {
                                height: ${15 * ySpaceRem}rem;
                              }
                            `}
                          >
                            <Map
                              onZoomEnd={map => {
                                updateMap(map);
                              }}
                              onMoveEnd={map => {
                                updateMap(map);
                              }}
                              style="mapbox://styles/webba-bv/ck797951y0w051ipgv5zt775r"
                              center={mapCenter}
                              zoom={[mapZoom]}
                            >
                              <>
                                {Object.entries(tileJsonSources).map(
                                  ([id, source]) => (
                                    <Source
                                      key={id}
                                      id={id}
                                      tileJsonSource={source}
                                    />
                                  )
                                )}

                                {climateTheme?.node?.climateThemeCustomFields?.map?.mapboxTilesets?.map(
                                  (tileset, i) => (
                                    <Layer
                                      key={`${climateTheme.node?.slug}-${i}`}
                                      type="raster"
                                      id={`raster-layer-${climateTheme.node?.slug}-${i}`}
                                      sourceId={tileset?.id}
                                      before="admin-1-boundary-bg"
                                      paint={{ "raster-opacity": 0.7 }}
                                    />
                                  )
                                )}

                                <ZoomControl />
                              </>
                            </Map>
                          </div>

                          <div
                            style={{
                              backgroundColor: colors.white,
                              padding: `${0.5 * ySpaceRem}rem ${0.5 *
                                xSpaceRem}rem`,
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                              overflow: "hidden",
                            }}
                          >
                            <ul
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                margin: `${-0.5 * ySpaceRem}rem ${-0.5 *
                                  xSpaceRem}rem`,
                              }}
                            >
                              {climateTheme?.node?.climateThemeCustomFields?.map?.legend?.map(
                                (item, i) => (
                                  <li
                                    key={i}
                                    style={{
                                      display: "flex",
                                      margin: `${0.5 * ySpaceRem}rem ${0.5 *
                                        xSpaceRem}rem`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: `${1 * ySpaceRem}rem`,
                                        width: `${1 * xSpaceRem}rem`,
                                        opacity: 0.7,
                                        ...(item?.type === "gradient"
                                          ? {
                                              background: `linear-gradient(90deg, ${item.colorGradient
                                                ?.map(item => item?.color)
                                                .join(", ")})`,
                                            }
                                          : {
                                              backgroundColor:
                                                item?.color || "",
                                            }),
                                        border: `1px solid ${colors.darkGrey}`,
                                      }}
                                    />

                                    <div
                                      style={{
                                        marginLeft: `${0.5 * xSpaceRem}rem`,
                                      }}
                                    >
                                      {item?.label}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <div
                            style={{
                              backgroundColor: colors.white,
                              padding: `${0.5 * ySpaceRem}rem ${0.5 *
                                xSpaceRem}rem`,
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                            }}
                            css={`
                              ul {
                                list-style-type: disc;
                                padding-left: ${xSpaceRem}rem;
                              }

                              li + li {
                                margin-top: ${0.5 * ySpaceRem}rem;
                              }
                            `}
                            dangerouslySetInnerHTML={{
                              __html:
                                climateTheme?.node?.climateThemeCustomFields
                                  ?.map?.explanation || "",
                            }}
                          />

                          <a
                            href="https://avecodebondt.geoapps.nl/laatunietverrassen/laatunietverrassen#5641f35d-2cd9-4f08-bf08-6bcae41c7656"
                            target="_blank"
                            rel="noopener"
                            style={{
                              color: colors.black,
                              textDecoration: "underline",
                            }}
                          >
                            Bekijk hier alle kaarten van de klimaatatlas van het
                            gebied Zuidoost Drenthe en Noordoost Overijssel.
                          </a>
                        </div>
                      </div>

                      <div
                        style={{
                          margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                          flexGrow: 1,
                          flexBasis: `${11 * xSpaceRem}rem`,
                          backgroundColor: colors.mediumBlue,
                          color: colors.white,
                          padding: `${ySpaceRem}rem`,
                        }}
                      >
                        <p>
                          <strong>Loopt u een specifiek risico?</strong>
                        </p>

                        <p>
                          Heeft u vragen over de kaart of maakt u zich zorgen
                          over een van de effecten in uw omgeving? Kijk dan in{" "}
                          <Link
                            to="/veelgestelde-vragen/"
                            style={{ textDecoration: "underline" }}
                          >
                            de veelgestelde vragen
                          </Link>{" "}
                          of neem{" "}
                          <Link
                            to="/over-ons/"
                            style={{ textDecoration: "underline" }}
                          >
                            contact op met uw eigen gemeente
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: `${ySpaceRem}rem`,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                          flexGrow: 999,
                          flexBasis: `${25 * xSpaceRem}rem`,
                        }}
                      >
                        <div style={{ marginBottom: `${1.5 * ySpaceRem}rem` }}>
                          <Blocks
                            blocks={
                              climateTheme?.node?.blocks as BlockFragment[]
                            }
                          />
                        </div>

                        <div style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                          <h2
                            style={{
                              fontFamily: fontStacks.cubano,
                              marginBottom: `${ySpaceRem}rem`,
                              borderBottom: `1px solid ${colors.black}`,
                              paddingBottom: `${0.5 * ySpaceRem}rem`,
                            }}
                          >
                            Verhalen over {climateTheme?.node?.title}
                          </h2>

                          <Grid style={{ marginBottom: `${ySpaceRem}rem` }}>
                            {climateTheme?.node?.climateThemeCustomFields?.climateThemesStories
                              ?.slice(0, 2)
                              .map(
                                (story, i) =>
                                  story && (
                                    <StoryPreview key={i} story={story} />
                                  )
                              )}
                          </Grid>

                          <Link
                            to="/verhalen/"
                            style={{
                              borderRadius: "1.5rem",
                              border: "none",
                              backgroundColor: colors.orange,
                              fontSize: "1rem",
                              fontWeight: 700,
                              fontFamily: fontStacks.univiaPro,
                              color: colors.white,
                              paddingTop: `${0.25 * ySpaceRem}rem`,
                              paddingBottom: `${0.25 * ySpaceRem}rem`,
                              paddingLeft: `${0.5 * ySpaceRem}rem`,
                              paddingRight: `${0.5 * ySpaceRem}rem`,
                              marginBottom: `${0.5 * ySpaceRem}rem`,
                            }}
                          >
                            Bekijk meer verhalen &rarr;
                          </Link>
                        </div>
                      </div>

                      <div
                        style={{
                          margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                          flexGrow: 1,
                          flexBasis: `${11 * xSpaceRem}rem`,
                          padding: `${ySpaceRem}rem`,
                        }}
                      />
                    </div>
                  </div>
                </Center>
              </WavySection>

              <Footer backgroundColor={colors.lightestGrey} />
            </>
          );
        }}
      </PersistedData>
    </>
  );
});

export default ClimateTheme;

export const pageQuery = graphql`
  query ClimateThemePage {
    wp {
      climateThemes {
        edges {
          node {
            blocks {
              ...Block
            }
            seo {
              ...SEO
            }
            title
            slug
            uri
            menuOrder
            climateThemeCustomFields {
              map {
                title
                mapboxTilesets {
                  id
                }
                legend {
                  type
                  color
                  colorGradient {
                    color
                  }
                  label
                }
                explanation
              }
              climateThemesStories {
                ... on Wp_Story {
                  ...Story
                }
              }
            }
          }
        }
      }
    }
  }
`;
