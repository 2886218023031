import React, { FC, Fragment, useRef, useContext, useState } from "react";
import { BlockFragment, BlocksQuery } from "../../graphql-types";
import Img, { FluidObject } from "gatsby-image";
import { LayoutContext } from "./layout";
import { ySpaceRem, colors, xSpaceRem, fontStacks } from "../global-style";
import { PersistedData } from "./persisted-data";
import { navigate, useStaticQuery, graphql } from "gatsby";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

type Props = {
  blocks: BlockFragment[];
};

/**
 * Generates JSX given an array of WordPress Gutenberg blocks.
 */
export const Blocks: FC<Props> = props => {
  const { blocks } = props;

  const { remInPx } = useContext(LayoutContext);
  const imageBlocksRef = useRef({} as { [id: string]: HTMLDivElement });

  const [postalCodeInput, setPostalCodeInput] = useState({
    value: "",
    touched: false,
  });

  const data = useStaticQuery<BlocksQuery>(graphql`
    query Blocks {
      wp {
        generalSettings {
          url
        }
      }

      storm: file(relativePath: { eq: "storm.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      chatPromo: file(relativePath: { eq: "chat-promo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      css={`
        & > * + * {
          margin-top: ${0.5 * ySpaceRem}rem;
        }

        & > * + h3,
        & > * + h2 {
          margin-top: ${ySpaceRem}rem;
        }

        & a {
          color: ${colors.mediumBlue};
          text-decoration: underline;
        }
      `}
    >
      {blocks.map((block, i) => (
        <Fragment key={i}>
          {(() => {
            /**
             * core/paragraph
             */
            // if (block.__typename === "Wp_CoreParagraphBlock") {
            //   return (
            //     <p
            //       dangerouslySetInnerHTML={{
            //         __html:
            //           (block.attributes?.__typename ===
            //             "Wp_CoreParagraphBlockAttributes" &&
            //             block.attributes.content.replace(
            //               data.wp.generalSettings?.url as string,
            //               ""
            //             )) ||
            //           "",
            //       }}
            //     />
            //   );
            // }
            if (
              block.__typename === "Wp_CoreParagraphBlock" &&
              block.attributes?.__typename === "Wp_CoreParagraphBlockAttributes"
            ) {
              return (
                <p
                  dangerouslySetInnerHTML={{
                    __html: block.attributes.content.includes(".pdf")
                      ? block.attributes.content || ""
                      : block.attributes.content.replace(
                          data.wp.generalSettings?.url as string,
                          ""
                        ),
                  }}
                />
              );
            }

            /**
             * core/image
             */
            if (
              block.__typename === "Wp_CoreImageBlock" &&
              block.attributes?.__typename === "Wp_CoreImageBlockAttributes"
            ) {
              const id = block.attributes?.mediaItem?.id || "";
              const paddingYRem =
                (imageBlocksRef.current[id] &&
                  ySpaceRem / 2 -
                    ((imageBlocksRef.current[id].getBoundingClientRect()
                      .height /
                      remInPx) %
                      (ySpaceRem / 2))) ||
                0;

              return (
                <div
                  style={{
                    paddingTop: `${0.5 * paddingYRem}rem`,
                    paddingBottom: `${0.5 * paddingYRem}rem`,
                    transition: "padding 0.25s ease",
                  }}
                >
                  <div
                    ref={el => {
                      if (el) {
                        imageBlocksRef.current[id] = el;
                      }
                    }}
                  >
                    {/*<Zoom wrapStyle={{ width: "100%" }}>*/}
                    <Img
                      fluid={
                        block.attributes?.mediaItem?.localFile?.childImageSharp
                          ?.fluid as FluidObject
                      }
                      style={{ width: "100%" }}
                      alt={
                        block.attributes.alt ||
                        block.attributes?.mediaItem?.altText ||
                        ""
                      }
                    />
                    {/*</Zoom>*/}
                  </div>
                </div>
              );
            }

            /**
             * core/heading
             */
            if (
              block.__typename === "Wp_CoreHeadingBlock" &&
              block.attributes?.__typename === "Wp_CoreHeadingBlockAttributes"
            ) {
              const content = block.attributes?.content;
              const level = block.attributes?.level;

              return (
                (level === 1 && (
                  <h1 dangerouslySetInnerHTML={{ __html: content || "" }} />
                )) ||
                (level === 2 && (
                  <h2 dangerouslySetInnerHTML={{ __html: content || "" }} />
                )) ||
                (level === 3 && (
                  <h3 dangerouslySetInnerHTML={{ __html: content || "" }} />
                )) ||
                (level === 4 && (
                  <h4 dangerouslySetInnerHTML={{ __html: content || "" }} />
                ))
              );
            }

            /**
             * core/gallery
             */
            if (
              block.__typename === "Wp_CoreGalleryBlock" &&
              block.attributes?.__typename === "Wp_CoreGalleryBlockAttributes"
            ) {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      margin: `${-0.5 * ySpaceRem}rem ${-0.5 * xSpaceRem}rem`,
                    }}
                  >
                    {block.attributes?.mediaItems?.edges?.map(
                      (edge, i) =>
                        edge && (
                          <div
                            key={i}
                            style={{
                              flexGrow: 1,
                              margin: `${0.5 * ySpaceRem}rem ${0.5 *
                                xSpaceRem}rem`,
                            }}
                          >
                            {/*<Zoom wrapStyle={{ width: "100%" }}>*/}
                            <Img
                              fluid={
                                edge.node?.localFile?.childImageSharp
                                  ?.fluid as FluidObject
                              }
                              alt={edge.node?.altText || ""}
                              style={{ width: "100%" }}
                            />
                            {/*</Zoom>*/}
                          </div>
                        )
                    )}
                  </div>
                </div>
              );
            }

            /**
             * core/list
             */
            if (block.__typename === "Wp_CoreListBlock") {
              return (
                <ul
                  dangerouslySetInnerHTML={{
                    __html:
                      block.attributes?.values.replace(
                        data.wp.generalSettings?.url as string,
                        ""
                      ) || "",
                  }}
                  style={{
                    listStyleType: "disc",
                    paddingLeft: `${xSpaceRem}rem`,
                  }}
                />
              );
            }

            /**
             * core/quote
             */
            if (
              block.__typename === "Wp_CoreQuoteBlock" &&
              block.attributes?.__typename === "Wp_CoreQuoteBlockAttributes"
            ) {
              return (
                <blockquote
                  dangerouslySetInnerHTML={{
                    __html:
                      block.attributes?.value.replace(
                        data.wp.generalSettings?.url as string,
                        ""
                      ) || "",
                  }}
                  style={{
                    fontSize: "1.25rem",
                    color: colors.mediumGrey,
                    borderLeft: `6px solid ${colors.mediumBlue}`,
                    paddingLeft: `${0.25 * xSpaceRem}rem`,
                    paddingTop: `${0.25 * ySpaceRem}rem`,
                    paddingBottom: `${0.25 * ySpaceRem}rem`,
                  }}
                />
              );
            }

            /**
             * acf/video
             */
            if (block.__typename === "Wp_AcfVideoBlock") {
              return (
                <div
                  style={{
                    paddingTop: `${0.5 * ySpaceRem}rem`,
                    paddingBottom: `${0.5 * ySpaceRem}rem`,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                      ...(block.videoCustomFields?.video?.caption && {
                        marginBottom: `${0.5 * ySpaceRem}rem`,
                      }),
                    }}
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      src={
                        block.videoCustomFields?.video?.url?.replace(
                          data.wp.generalSettings?.url as string,
                          ""
                        ) || ""
                      }
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen={false}
                    />
                  </div>

                  {block.videoCustomFields?.video?.caption && (
                    <p
                      style={{ fontStyle: "italic" }}
                      dangerouslySetInnerHTML={{
                        __html: block.videoCustomFields.video.caption,
                      }}
                    />
                  )}
                </div>
              );
            }

            /**
             * acf/climate-check
             */
            if (block.__typename === "Wp_AcfClimateCheckBlock") {
              return (
                <div style={{ overflow: "hidden" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: `${0.5 * ySpaceRem}rem`,
                      paddingBottom: `${0.5 * ySpaceRem}rem`,
                      margin: `${-0.5 * ySpaceRem}rem ${-0.5 * xSpaceRem}rem`,
                    }}
                  >
                    <div
                      style={{
                        flexBasis: `${11 * xSpaceRem}rem`,
                        flexGrow: 1,
                        margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      }}
                      css={`
                        & > p + p {
                          margin-top: ${0.5 * ySpaceRem}rem;
                        }
                      `}
                      dangerouslySetInnerHTML={{
                        __html:
                          block.climateCheckCustomFields?.content?.replace(
                            data.wp.generalSettings?.url as string,
                            ""
                          ) || "",
                      }}
                    />

                    <div
                      style={{
                        flexBasis: `${11 * xSpaceRem}rem`,
                        flexGrow: 1,
                        margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: colors.mediumBlue,
                          color: colors.white,
                          padding: `${ySpaceRem}rem`,
                          borderRadius: "1rem",
                        }}
                      >
                        <h3
                          css={`
                            font-size: 1.75rem;

                            @media (min-width: 500px) {
                              font-size: 2.25rem;
                            }

                            @media (min-width: 800px) {
                              font-size: 2.75rem;
                            }
                          `}
                          style={{
                            fontFamily: fontStacks.cubano,
                            lineHeight: `${1.5 * ySpaceRem}rem`,
                            textAlign: "center",
                            marginBottom: `${0.5 * ySpaceRem}rem`,
                            fontWeight: 500,
                          }}
                        >
                          Doe de
                          <br /> klimaatcheck!
                        </h3>

                        <p style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                          Het klimaat verandert. Steeds vaker is er extreme
                          hitte, droogte of regenval. Bent u benieuwd naar de
                          mogelijke effecten hiervan in uw eigen omgeving? Vul
                          uw postcode in en bekijk de kaart.
                        </p>

                        <PersistedData>
                          {({ postalCode, setPostalCode }) => {
                            if (!postalCodeInput.touched && postalCode) {
                              setPostalCodeInput({
                                value: postalCode,
                                touched: true,
                              });
                            }

                            return (
                              <form
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                onSubmit={e => {
                                  e.preventDefault();
                                  setPostalCode(postalCodeInput.value, () => {
                                    navigate("/klimaatthemas/hitte");
                                  });
                                }}
                              >
                                <input
                                  value={postalCodeInput.value}
                                  onChange={e => {
                                    setPostalCodeInput({
                                      value: e.target.value,
                                      touched: true,
                                    });
                                  }}
                                  placeholder="Uw postcode"
                                  style={{
                                    width: "100%",
                                    marginBottom: `${0.5 * ySpaceRem}rem`,
                                    height: `${1.5 * ySpaceRem}rem`,
                                    borderRadius: "0.5rem",
                                    border: `2px solid ${colors.lightGrey}`,
                                    paddingLeft: `${0.5 * xSpaceRem}rem`,
                                    paddingRight: `${0.5 * xSpaceRem}rem`,
                                    fontSize: "1rem",
                                    fontFamily: fontStacks.univiaPro,
                                  }}
                                />

                                <button
                                  style={{
                                    borderRadius: "1.5rem",
                                    border: "none",
                                    backgroundColor: colors.orange,
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                    fontFamily: fontStacks.univiaPro,
                                    color: colors.white,
                                    paddingTop: `${0.25 * ySpaceRem}rem`,
                                    paddingBottom: `${0.25 * ySpaceRem}rem`,
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Bekijk de kaart &rarr;
                                </button>
                              </form>
                            );
                          }}
                        </PersistedData>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }

            /**
             * acf/next-meeting
             *
             * not needed for now
             */
            if (block.__typename === "Wp_AcfNextMeetingBlock") {
              return <div>acf/next-meeting ...</div>;
            }

            if (block.__typename === "Wp_AcfInfographicBlock") {
              return null;

              // if (block.infographicCustomFields?.type === "hitte") {
              //   return <div>hitte</div>;
              // }
              //
              // if (block.infographicCustomFields?.type === "droogte") {
              //   return <div>droogte</div>;
              // }
              //
              // if (block.infographicCustomFields?.type === "wateroverlast") {
              //   return (
              //     <div
              //       style={{ backgroundColor: "darkblue", color: colors.white }}
              //     >
              //       wateroverlast
              //     </div>
              //   );
              // }
            }

            /**
             * acf/downloads
             */
            if (block.__typename === "Wp_AcfDownloadsBlock") {
              return (
                <div>
                  {block.downloadsCustomFields?.downloads?.map(
                    (download, i) => (
                      <a
                        download
                        href={download?.file?.localFile?.publicURL as string}
                        key={i}
                        style={{
                          display: "block",
                          borderRadius: `${0.25 * ySpaceRem}rem`,
                          backgroundColor: colors.lightestGrey,
                          padding: `${0.25 * ySpaceRem}rem ${0.5 *
                            xSpaceRem}rem`,
                          ...(i > 0 && { marginTop: `${0.5 * ySpaceRem}rem` }),
                        }}
                        dangerouslySetInnerHTML={{
                          __html: download?.file?.title as string,
                        }}
                      />
                    )
                  )}
                </div>
              );
            }

            if (block.__typename === "Wp_AcfChatPromoBlock") {
              return (
                <div
                  style={{
                    overflow: "hidden",
                    paddingTop: `${ySpaceRem}rem`,
                    paddingBottom: `${ySpaceRem}rem`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: `${-0.5 * ySpaceRem}rem`,
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "18rem",
                        flexGrow: 1,
                        margin: `${0.5 * ySpaceRem}rem`,
                      }}
                    >
                      <h3 style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                        {block.chatPromoCustomFields?.title}
                      </h3>

                      <div
                        style={{ marginBottom: `${0.75 * ySpaceRem}rem` }}
                        dangerouslySetInnerHTML={{
                          __html:
                            block.chatPromoCustomFields?.description?.replace(
                              data.wp.generalSettings?.url as string,
                              ""
                            ) || "",
                        }}
                      />

                      <button
                        style={{
                          borderRadius: "1.5rem",
                          border: "none",
                          backgroundColor: colors.orange,
                          fontSize: "1rem",
                          fontWeight: 700,
                          fontFamily: fontStacks.univiaPro,
                          color: colors.white,
                          paddingTop: `${0.25 * ySpaceRem}rem`,
                          paddingBottom: `${0.25 * ySpaceRem}rem`,
                          paddingLeft: `${0.75 * ySpaceRem}rem`,
                          paddingRight: `${0.75 * ySpaceRem}rem`,
                          cursor: "pointer",
                          textAlign: "left",
                          display: "flex",
                        }}
                        onClick={() => {
                          // @ts-ignore
                          if (quriobot !== "undefined") quriobot.start();
                        }}
                      >
                        Start nu de chat{" "}
                        <span style={{ marginLeft: `${0.5 * xSpaceRem}rem` }}>
                          &rarr;
                        </span>
                      </button>
                    </div>
                    <div
                      style={{
                        flexBasis: "18rem",
                        flexGrow: 1,
                        margin: `${0.5 * ySpaceRem}rem`,
                      }}
                    >
                      <Img
                        fluid={
                          data.chatPromo?.childImageSharp?.fluid as FluidObject
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div>
                <em>geen implementatie: {block.__typename}</em>
              </div>
            );
          })()}
        </Fragment>
      ))}
    </div>
  );
};
